.textTitle {
  text-align: center;
}
h2.textTitle {
  margin: 0;
  width: 300px;
}
.gridPuntos {
  margin-top: 20px;
}
.line {
  width: 95%;
  margin: 0px 2.5%;
  color: #f0f0f0;
}

.partner {
  & h3 {
    padding: 4%;
    width: 50%;
  }
  & span {
    display: flex;
    justify-content: space-between;
  }
}
.card__detail {
  width: 100%;
  color: #3f4d55;
  & h3 {
    margin: 0;
  }
}
.state__point {
  width: 60px;
  height: 16px;
  border-radius: 5px;
  padding: 6px 15px;
  font-weight: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-size: 0.75em;
  text-align: center;
  background-color: #d9d9d9;
  margin-top: 3%;
  margin-right: 4%;
}
.detail__content {
  & div {
    display: flex;
    justify-content: space-between;
    padding: 4%;
    & span:last-child {
      font-weight: bolder;
    }
  }
}
.assign__partner {
  display: flex;
  padding: 2% 0;
  width: 100%;
  
}
.div__inside{
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
  & span:first-child{
    margin-left: 22px;
  }
  & span:last-child {
    font-weight: bolder;
    float:right;
    margin-right: 22px;
  }

}
.button__container{
  width: 100%;
  display: flex;
  justify-content: center;
}