.settings__container {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  padding: 20px 40px;
  color: #3f4d55;
}
.divider__settings{
  width: 2px;
  height: 300px;
  border-right: 2px solid #a1a3a5;
}
.header__title {
  width: 100%;
  margin: 0;
  
  text-align: center;
}
.services_swap_styles{
  p {
    font-size: 1.2rem
  }
}