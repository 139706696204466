.orders__container{
  width: 95%;
  margin: 60px auto 0px auto;
  @media only screen and (max-width: 1280px) {
    margin: 60px auto 0px auto;
    width: 95%;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .first__form__filters {
    width: 56%;
    @media only screen and (max-width: 992px) {
      overflow: auto;
      width: 100%;
      display: flex;
    }
  }
  .second__form__filters {
    width: 40%;
    @media only screen and (max-width: 992px) {
      width: 100%;
      display: flex;
    }
  }
}
.state {
  width: 150px;
  padding-right: 20px;
  height: 40px;
}
.state__label {
  padding: 20px;
}
.header__on__orders {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.export__btn {
  position: fixed;
  width: 5%;
  left: 10px;
  bottom: 10px;
}
.header__titles__orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
.order__date {
  margin-top: -10px;
}
.content__table {
  margin-bottom: 50px;
}

.progress__order {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
