.order__content {
  padding: 0 20px 40px 20px;
  overflow-y: auto;
}

.order__title {
  h3 {
    margin: 0;
  }
  i {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: black;
    margin-right: 10px;
  }
}

.order__card {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  h3 {
    margin: 0 0 10px 0;
  }
  p {
    &.note {
      font-size: 14px;
      margin: 0 0 15px 0;
      line-height: 1.3;
    }
  }
}

.order__cardContent {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
}

.order__statusContent {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  margin-top: 20px;
}

.order__row {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  row-gap: 20px;
  &.col-2 {
    grid-template-columns: 40% 1fr;
    column-gap: 10px;
  }
}

.order__col {
  line-height: 1.3;
}

.text-secondary {
  color: black;
}

.text-bold {
  font-weight: 700;
}

.text-total {
  font-size: 18px;
  font-weight: bold;
}

.order__comunication {
  a {
    color: green;
    font-size: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
}

.order__field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    margin-bottom: 6px;
  }
}

.order__loading {
  display: grid;
  column-gap: 20px;
  padding: 0 20px;
}

.order__loadingItem {
  height: 200px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
