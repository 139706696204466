.statistics__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statistics__date {
  margin-top: -10px;
}
.card {
  padding: 10px 10px 10px 30px;
  height: 225px;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #3f4d55;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(34, 42, 66, 0);
  border-top-color: rgb(82, 95, 127);
  .card__title{
    text-align: center;
    @media screen and (max-width: 1280px){
      font-size: 1.1vw;
    }
    @media screen and (min-width: 1600px){
      font-size: 0.9vw;
    }
    
    font-size: 1.1vw;
  }
}
.card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card__description{
  margin-top:15px;
  text-align: center;
}
.card__description1{
  margin-top: 6px;
  text-align: center;
}
.help__text{
  float:right;
}
.fab {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  text-align: center;
  align-items: center;
}
.fab__title {
  font-size: 30px;
  width: 100%;
  text-align: center;
}
.divider {
  width: 95%;
  margin-top: -5px;
}

.resume {
  display: flex;
  justify-content: center;
}
h2 {
  color: #3f4d55;
}
.resume__title {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .title{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 25px;
  }
  p{
    cursor:help;
    float:right;
    margin-top: 3px;
    span{
      font-weight: bold;
      margin-left: 5px;
    }
  }
  
}
.resume__card {
  padding: 10px;
  color: #3f4d55;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: rgba(34, 42, 66, 0);
  border-top-color: rgb(82, 95, 127);
}
.card__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  padding-right: 30px;
  font-size: 1rem;
  span{
    font-weight: bold;
  }
  
}

.progress {
  display: flex;
  margin-top: -12px;
  width: 95%;
  padding-left: 30px;
}
.legend__container{
  margin: 0 auto;
  max-width: 930px;
  width: 80%;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #3f4d55;
  border-radius: 5px;
  font-size: 0.9em;
  padding: 0 8px;
  .item__legend{
    display: flex;
    width: 230px;
    font-weight:500;
  }
  .item__legend:last-child{
    width: 150px;
  }
}
.legend__type{
  border-radius: 10px;
  width: 50px;
  height: 18px;
  margin-right: 10px;
}
