.edit__content {
  margin-top: -50px;
  padding: 10px;
  width: 700px;
  position: relative;
  z-index: 1;
}
.form {
  padding: 10px;
}
.form__content {
  display: flex;
  margin-top: 10px;
}
.text__field {
  width: 100%;
}
.text__field2 {
  margin-left: 10px;
}
.form__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.form__button {
  background: #2e0049;
  color: white;
}
