
.md__img{
  width: 100%;
  border-radius: 5px;
  object-fit: fill;
}
.md__header{
  color: #3f4d55;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h4 {
    margin: 0;
  }
  justify-content: space-between;
}
.md__info{
  color: #3f4d55;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  span:first-child{
    font-weight: 500;
  }
  margin: 2% 0;
}
.md__popover{
  color: #2c78a4;
  cursor: pointer;
}