.toolbar {
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  .links {
    a {
      color: white;
    }
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 10px;

    > * {
      flex: none;
    }
  }

  .button {
    text-transform: capitalize;
    font-weight: 700;
  }
}

.admin__button {
  background-color: white;
  color: white;
}

.nirex__logo {
  width: 140px;


  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
    object-fit: cover;
    object-position: left;
  }
}


.scroll-shadows {
  overflow: auto;

  background-image:
    /* Shadows */
    linear-gradient(to right, var(--primary), var(--primary)),
    linear-gradient(to right, var(--primary), var(--primary)),

    /* Shadow covers */
    linear-gradient(to right, rgba(255, 255, 255, .25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(255, 255, 255, .25), rgba(255, 255, 255, 0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: var(--primary);
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}