
.partners{
    cursor:pointer;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    p{
        padding: 5px 4px;
        span{
            margin: 0 5px;
            color: black;
            font-weight: bold;
        }
    }
}
.partners:hover{
    background-color: rgb(141, 154, 226);
}