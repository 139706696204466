.points {
  width: 98%;
  color: #3f4d55;
  margin-bottom: 10px;
  padding: 1%;
  display: flex;
}
.points__icons {
  width: 2%;
}
.points__content {
  margin-left: 20px;
  width: 70%;
}
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  & div {
    display: flex;
    flex-wrap: wrap;
    & span {
      margin-right: 5px;
      font-size: 0.9em;
    }
  }
  .content__title {
    font-weight: bold;
    font-style: italic;
  }
  .content__description {
    font-style: italic;
  }
}
.points__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  width: 400px;
  .points__button {
    background-color: #2e0049;
    color: white;
  }
}
.points__status {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & p {
    float: right;
    font-size: 0.7em;
    color: gray;
    margin: 0;
  }
}
.points__connect {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}
