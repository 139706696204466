.loader {
    content: "";
    display: table;
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    background-size: 64px 64px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://s3-sa-east-1.amazonaws.com/nirex2.0/Web+app/App_clientes/assets/Home/loader.png");
    animation: rotating 1s linear infinite;
    z-index: 1;
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }