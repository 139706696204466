.header {
  display: flex;
  padding-bottom: 20px;
}
.state {
  width: 150px;
  padding-right: 20px;
  height: 40px;
}
.state__label {
  padding: 20px;
}
.header__titles {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order__date {
  margin-top: -10px;
}
.export__btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content__table {
  margin-bottom: 50px;
}

.progress__order {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input__checkbox {
  margin: 1px 0px 1px 0px;
  font-weight: 500;
  color: #3f4d55;
  span{
    font-size:  1rem;
    margin-left: 1px;
  }
  div{
    input{
      margin-right: 1px;
    }
    font-size:  1.1rem;
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
